import React from "react";
import "./about.css";
import codingGif from "../../../img/coder.gif";

const About = () => {
  return (
    <div id="about" className="selectDisable">
      <h1 className="aboutHeading ">About Me</h1>
      <section className="about">
        <div className="aboutImgContainer">
          <img src={codingGif} alt="" />
        </div>
        <div className="aboutInfo">
          <p>
            I'm Ram, a self-taught front-end developer with experience and a
            goal to become a full-stack (MERN) developer.
          </p>
          <p>
            I like to code things for web and turning innovative ideas into
            realities. I enjoy working with teams and I'm ready to learn new
            stuff. Currently on the lookout for a role in a lively environment
            where I can apply my skills and contribute to the company's growth.
            Always up for learning and growing!
          </p>
          <p></p>
        </div>
      </section>
    </div>
  );
};

export default About;
