import YouTubeClone from "./pImages/YouTubeClone.png";
import BingeHub from "./pImages/BingeHub.png";
import TheNewsStation from "./pImages/TheNewsStation.png";
import PassGen from "./pImages/PassGen.png";
import SimpleEncrypt from "./pImages/SimpleEncrypt.png";
import SpaceX from "./pImages/SpaceX.png";

export const projects = [
  {
    pName: "YouTube Clone",
    pImage: YouTubeClone,
    techStack: ["React", "Chakra UI"],
    github: "https://github.com/ramsaoji/YouTube-Clone",
    pUrl: "https://youtube-clone.techness.in/",
    pBrief:
      "A responsive web-app featuring a trending page, video playback, and search functionality, including search suggestions and dark mode.",
    pFeatures:
      "The platform's Trending Page offers real-time access to popular videos, and the intelligent search makes finding specific content easy. The Playback Page ensures seamless viewing with related video suggestions, and integrated Piped API as a data source and many more...",
  },
  {
    pName: "SpaceX Launches",
    pImage: SpaceX,
    techStack: ["React", "MUi"],
    github: "",
    pUrl: "https://spacex.techness.in/",
    pBrief:
      "A responsive web-app designed to provide comprehensive information about all SpaceX launches.",
    pFeatures:
      "The web-app delivers in-depth information on SpaceX launches, featuring an infinite scroll for dynamic launch list display. It seamlessly integrates the SpaceX API as the primary data source for real-time updates on mission details and many more...",
  },
  {
    pName: "Binge Hub",
    pImage: BingeHub,
    techStack: ["React", "MUi"],
    github: "https://github.com/ramsaoji/Binge-Hub",
    pUrl: "https://bingehub.techness.in/",
    pBrief:
      "A responsive web-app to find all information related to movies & web series.",
    pFeatures:
      "Trending Page to find all trending movies & series. Search to find specific movies/series. Also get info about IMDb rating, description, casting and trailer link, integrated MovieDB API as a data source and many more...",
  },
  {
    pName: "The News Station",
    pImage: TheNewsStation,
    techStack: ["React", "MUi"],
    github: "https://github.com/ramsaoji/The-News-Station",
    pUrl: "https://thenewsstation.techness.in/",
    pBrief: "A responsive web-app to find all top news headlines.",
    pFeatures:
      "Get latest top headlines and a breif about it along with its Author, Date and its Source with link, Also sort on different categories such as technology, entertainment, sports, etc. (Note - Filled with dummy data & also categories won't work due to API restriction)",
  },
  {
    pName: "Password Generator",
    pImage: PassGen,
    techStack: ["HTML", "CSS", "Javascript"],
    github: "https://github.com/ramsaoji/Password-Generator",
    pUrl: "https://passgen.techness.in/",
    pBrief: "A website to generate strong random passwords/passphrases.",
    pFeatures:
      "Generate strong random passwords upto 100 chars and passphrases upto 20 words along with some filters.",
  },
  {
    pName: "Simple Encrypt",
    pImage: SimpleEncrypt,
    techStack: ["HTML", "CSS", "Javascript"],
    github: "https://github.com/ramsaoji/Simple-Encrypt",
    pUrl: "https://simplencrypt.techness.in/",
    pBrief: "A website to encrypt plain text.",
    pFeatures:
      "Encrypt plain text with Advanced Encryption Standard (AES) using a key which can be decrypted only by the key you entered.",
  },
];
