import React from "react";
import "./skills.css";
import htmlLogo from "../../../logo/html-5.png";
import cssLogo from "../../../logo/css-3.png";
import jsLogo from "../../../logo/java-script.png";
import reactLogo from "../../../logo/react.png";
import muiLogo from "../../../logo/material-ui.svg";
import tailwindLogo from "../../../logo/tailwindcss-icon.svg";
import mySqlLogo from "../../../logo/mysqlnew.png";
import meteorJsLogo from "../../../logo/meteorjs.png";
import mongoDbLogo from "../../../logo/mongodb.png";
import graphQlLogo from "../../../logo/graphql.png";

// const logos = [htmlLogo, cssLogo, jsLogo, reactLogo, tailwindLogo];

const logos = [
  {
    path: htmlLogo,
    name: "HTML5",
  },
  {
    path: cssLogo,
    name: "CSS3",
  },
  {
    path: jsLogo,
    name: "JavaScript",
  },
  {
    path: reactLogo,
    name: "React JS",
  },
  {
    path: meteorJsLogo,
    name: "Meteor JS",
  },
  {
    path: muiLogo,
    name: "MUi",
  },
  {
    path: tailwindLogo,
    name: "Tailwind",
  },
  {
    path: mySqlLogo,
    name: "MySQL",
  },
  {
    path: mongoDbLogo,
    name: "MongoDB",
  },
  {
    path: graphQlLogo,
    name: "GraphQL",
  },
];

const Skills = () => {
  return (
    <div id="skills" className="selectDisable">
      <h1 className="skillsHeading">My Skills</h1>
      <section className="skills ">
        <div className="skillsLogos ">
          {logos.map((logo) => {
            // console.log(logo.path);
            return (
              <div className="tooltip" key={logo.name}>
                <img className="logos" src={logo.path} alt={logo.name} />
                <span className="tooltiptext">{logo.name}</span>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default Skills;
