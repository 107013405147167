import React from "react";
import "./main.css";
import Intro from "./Intro/Intro";
import About from "./About/About";
import Skills from "./Skills/Skills";
import Portfolio from "./Portfolio/Portfolio";
import Contact from "./Contact/Contact";

const Main = () => {
  return (
    <main className="mainContainer">
      <Intro />
      <About />
      <Skills />
      <Portfolio />
      <Contact />
    </main>
  );
};

export default Main;
