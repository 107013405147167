import React from "react";
import "./darkToggle.css";
import { BsFillMoonStarsFill, BsFillSunFill } from "react-icons/bs";

const DarkToggle = ({ switchTheme, theme }) => {
  return (
    <div className="darkToggle" onClick={switchTheme}>
      {theme === "light" ? (
        <BsFillMoonStarsFill />
      ) : (
        <BsFillSunFill color="#ffc300" />
      )}
    </div>
  );
};

export default DarkToggle;
