import React from "react";
import "./contact.css";

const Contact = () => {
  return (
    <form
      id="contact"
      action="https://formsubmit.co/ramsaoji27@gmail.com"
      method="POST"
    >
      <div className="contact">
        <div className="contactHeadingContainer">
          <h1 className="contactHeading">Contact Me</h1>
          <p className="contactHeadingDesc">
            Drop a message. I will reach out as soon as possible.
          </p>
        </div>
        <div className="contactWrapper">
          <div className="inputContainer">
            <input type="text" placeholder="Name" name="Name" required />
            <input type="email" placeholder="Email" name="Email" required />
          </div>
          <div className="msg">
            <textarea
              type="text"
              name="Message"
              placeholder="Message"
              required
            ></textarea>
          </div>
          <div className="btnContainer">
            <button type="submit" className="btn">
              Send Message
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Contact;
