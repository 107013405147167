import React from "react";
import "./footer.css";
import RSLogo from "../../logo/RSlogo.png";

const Footer = () => {
  return (
    <div className="footer">
      <img src={RSLogo} alt="Logo" /> Built with lots of ⚡💻❤️ by Ram Saoji
    </div>
  );
};

export default Footer;
