import React from "react";
import "./intro.css";
import codingPng from "../../../img/dev_male.png";
import Resume from "../../../data/file/Ram_Saoji_Resume.pdf";

const Intro = () => {
  return (
    <section id="intro" className="intro selectDisable">
      <div className="introInfo">
        <p>Hello, 👋 I am</p>
        <div className="name">
          <h1>Ram Saoji</h1>
          <h1 className="post">Frontend developer</h1>
        </div>
        <div className="description">
          <p>
            I love to create beautiful and smooth websites with great user
            experience.
          </p>
          <p>
            I'm open to learning, adapt to work in a team, freelance
            opportunities, and use my skills to solve complex UI challenges and
            provide value to the growth of an organization.
          </p>
        </div>
        <a href={Resume} download="Ram_Saoji_Resume.pdf">
          <button className="btn">Show Resume</button>
        </a>
      </div>
      <div className="inrtoImgContainer">
        <img className="vert-move" src={codingPng} alt="" />
      </div>
    </section>
  );
};

export default Intro;
