import React, { useState } from "react";
import "./navbar.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import DarkToggle from "../DarkToggle/DarkToggle";
import RSLogo from "../../logo/RSlogo.png";

const Navbar = ({ switchTheme, theme }) => {
  const [open, setOpen] = useState(false);

  const hamburger = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  return (
    <div className="navbar selectDisable">
      <div className="title">
        <a href="#intro" onClick={() => setOpen(false)}>
          <img src={RSLogo} alt="Logo" />
        </a>
      </div>

      <div className="container">
        <ul
          className="navlink"
          style={
            open
              ? {
                  height: "260px",
                }
              : {}
          }
        >
          <a href="#about" onClick={() => setOpen(false)}>
            <li className="hover-underline-animation">About</li>
          </a>
          <a href="#skills" onClick={() => setOpen(false)}>
            <li className="hover-underline-animation">Skills</li>
          </a>
          <a href="#portfolio" onClick={() => setOpen(false)}>
            <li className="hover-underline-animation">Portfolio</li>
          </a>
          <a href="#contact" onClick={() => setOpen(false)}>
            {/* <li className="">Hire Me</li> */}
            <li className="btn">Hire Me</li>
          </a>
        </ul>

        <DarkToggle switchTheme={switchTheme} theme={theme} />
      </div>

      <div className="hamburgerIcon" onClick={hamburger}>
        {open ? <AiOutlineClose /> : <GiHamburgerMenu />}
      </div>
    </div>
  );
};
// style={open ? { top: "60px" } : {}}
export default Navbar;
