import React from "react";
import "./portfolio.css";
import Card from "./Card/Card";
import { BsLink45Deg } from "react-icons/bs";

const Portfolio = () => {
  return (
    <div id="portfolio" className="selectDisable">
      <div className="portfolioHeadingContainer">
        <h1 className="portfolioHeading">portfolio</h1>
        <p className="portfolioHeadingDesc">
          Have a look at some of the projects with demonstration.
        </p>
      </div>

      <section className="portfolio">
        <Card />
      </section>
      <div className="githubLine">
        <a
          href="https://github.com/ramsaoji"
          target="_blank"
          rel="noopener noreferrer"
        >
          Check out more projects on my github...
          <BsLink45Deg className="linkIcon" />
        </a>
      </div>
    </div>
  );
};

export default Portfolio;
