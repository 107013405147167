import React from "react";
import "./card.css";
import { projects } from "../../../../data/ProjectsInfo";
import { BsGithub } from "react-icons/bs";
// import { MdComputer } from "react-icons/md";
import { BsLink45Deg } from "react-icons/bs";

const Card = () => {
  return (
    <>
      {projects?.map((project) => {
        return (
          <div className="cardContainer" key={project?.pName}>
            <div className="wrapper">
              <div className="imgContainer">
                <a
                  href={project?.pUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={project?.pImage} alt={project?.pImage} />
                </a>
              </div>
              <div className="descriptionContainer">
                <h3 className="heading">
                  {/* {project.pName} */}
                  <a
                    href={project?.pUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {project?.pName}
                    <BsLink45Deg className="linkIcon" />
                  </a>
                </h3>
                <div className="techStackContainer">
                  <div className="techStack">
                    {project?.techStack?.map((ts) => {
                      return <h6 key={ts}>{ts}</h6>;
                    })}
                  </div>
                  {project?.github && (
                    <div className="icons">
                      <a
                        href={project.github}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <BsGithub />
                      </a>
                    </div>
                  )}
                </div>
                <div className="description">
                  <p>{project?.pBrief}</p>
                  <h3>Features</h3>
                  <p>{project?.pFeatures}</p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Card;
